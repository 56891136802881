import { ActionIcon, Box, Divider, Flex, ScrollArea, Skeleton, Slider, Space, Stack, Switch, Text, Title, px } from "@mantine/core"
import { Airport, MetarResponse, NewNotam, PirateWeather, TafResponse, WeatherTimeGroupWithAdditionalWeather } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import { IconX } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { P, match } from "ts-pattern"
import { RiskList } from "../safety/RiskList"
import { TimezoneContext } from "../safety/timezoneContext"
import { SafetyWeatherDetails } from "../weather/SafetyWeatherDetails"
import { useFlightRuleStyles } from "../weather/useFlightRuleStyles"
import { calculateTimeOfDayTimeToDisplayCore } from "../weather/utils"
import { useSavedAirportStyles } from "./useSavedAirportStyles"

const numberFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 })

export function SavedAirportDetailCard({
  airport,
  metar,
  weather,
  taf,
  timeGroups,
  notams,
  onSaveClick = () => {},
  onClose,
  onStartBrief = () => {},
  opened,
  saved = false,
  timeZone,
}: {
  airport: Airport
  metar: MetarResponse
  taf: TafResponse
  weather: PirateWeather | undefined
  timeGroups: WeatherTimeGroupWithAdditionalWeather[]
  notams: NewNotam[]
  onSaveClick?: (airport: Airport) => void
  onClose?: () => void
  onStartBrief?: (airport: Airport, time?: Date | null) => void
  opened: boolean
  saved?: boolean
  timeZone?: string
}) {
  const { classes, cx } = useSavedAirportStyles()
  const { classes: flightRulesClasses } = useFlightRuleStyles()
  const [rawMode, setRawMode] = useState(false)
  const infoWidth = 130
  const [sliderValue, setSliderValue] = useState(0)
  const [localSaved, setLocalSaved] = useState(false)

  useEffect(() => {
    setLocalSaved(saved)
  }, [saved])

  const { sliderMarks, minTime, maxTime, timeSpan, startTime } = useMemo(() => {
    const maxTime = timeGroups[timeGroups.length - 1].dateTo
    const minTime = timeGroups[0].dateFrom
    let startDay = dayjs(minTime).startOf("hour")
    while (startDay.isBefore(dayjs(minTime).startOf("minute"))) {
      startDay = startDay.add(15, "minute")
    }
    const startTime = startDay.toDate()

    const timeSpan = maxTime.getTime() - startTime.getTime()

    const sliderMarks = timeGroups.map((group, index) => {
      return {
        value: group.dateFrom.getTime() - startTime.getTime(),
        // label: formatDate(group.dateFrom, {time: true, timeZone: weather.timezone, includeTimezone: false})
      }
    })

    return {
      sliderMarks,
      minTime,
      maxTime,
      startTime,
      timeSpan,
    }
  }, [timeGroups])

  const selectedDate = useMemo(() => {
    const date = new Date(sliderValue + startTime.getTime())
    return date
  }, [sliderValue])

  const timeGroup = useMemo(() => {
    const timeGroup =
      timeGroups.find((tg) => {
        const day = dayjs(selectedDate)
        return day.isSameOrAfter(tg.dateFrom) && day.isSameOrBefore(tg.dateTo)
      }) ?? timeGroups[0]

    return timeGroup
  }, [selectedDate])

  const timeOfDayTimeToDisplay = calculateTimeOfDayTimeToDisplayCore(timeGroup.dateFrom, timeGroup.sunrise, timeGroup.sunset)

  const handleSaveClick = useCallback(() => {
    //console.log("handle save click: ", onSaveClick)
    onSaveClick(airport)
    setLocalSaved(!localSaved)
  }, [airport, onSaveClick, setLocalSaved, localSaved])

  const handleStartBrief = () => {
    onStartBrief(airport)
  }

  return (
    <Flex direction="column" h="100%" mah="100%">
      <Flex justify="space-between" mb="sm" p="xs">
        <Box>
          <ActionIcon onClick={onClose}>
            <IconX fontWeight={600} strokeWidth={3} color="black" />
          </ActionIcon>
        </Box>
        <Flex align="center" gap={6}>
          <ActionIcon onClick={handleStartBrief} fw={700}>
            <Text
              span
              color="black"
              className={cx("material-symbols-outlined", classes.saveAirportIcon)}
              style={{
                fontVariationSettings: "'wght' 700",
                transform: "rotate(-30deg)",
                marginTop: "-6px",
              }}
            >
              send
            </Text>
          </ActionIcon>
          <ActionIcon onClick={handleSaveClick}>
            <Text span color="black" className={cx("material-symbols-outlined", classes.saveAirportIcon, { saved: localSaved })}>
              favorite
            </Text>
          </ActionIcon>
        </Flex>
      </Flex>

      <ScrollArea style={{ flexGrow: 1 }}>
        <Flex justify="space-between" pb="sm" px="xl">
          <Box>
            <Title order={4} fz={px("1.3rem")}>
              <Flex align="center">
                <span className="material-symbols-outlined" style={{}}>
                  altitude
                </span>
                <Text ml={5}>{numberFormatter.format(airport.elevation)}'</Text>
              </Flex>
            </Title>
            <Title order={3} fz={px("2.3rem")}>
              {airport.icaoId ?? airport.faaAirportId}
            </Title>
            {timeGroup.weatherDetails.flightRules != null && (
              <Box className={flightRulesClasses[timeGroup.weatherDetails.flightRules]} fw={600}>
                {timeGroup.weatherDetails.flightRules} conditions
              </Box>
            )}

            <Switch
              labelPosition="left"
              label="Raw"
              fw={700}
              checked={rawMode}
              onChange={(e) => {
                setRawMode(e.target.checked)
              }}
              mt="sm"
            />
          </Box>
          <Box>
            <Stack spacing="xs">
              <Flex align="center" fw={600}>
                <span className={cx("material-symbols-outlined", classes.overviewInfoIcon)}>calendar_today</span>
                <Text span ml="xs" w={infoWidth}>
                  {formatDate(selectedDate, { date: { format: "long" }, timeZone })}
                </Text>
              </Flex>
              <Flex align="center" fw={600}>
                <span className={cx("material-symbols-outlined", classes.overviewInfoIcon)}>flight_takeoff</span>
                <Text span ml="xs" w={infoWidth} tt="lowercase">
                  {formatDate(selectedDate, { time: true, timeZone })}
                </Text>
              </Flex>
              <Flex align="center" fw={600}>
                <span className={cx("material-symbols-outlined", classes.overviewInfoIcon)}>
                  {timeOfDayTimeToDisplay === "sunset" ? "clear_night" : "sunny"}
                </span>
                <Text span ml="xs" w={infoWidth}>
                  {match({ timeOfDayTimeToDisplay, timeGroup })
                    .with(
                      { timeGroup: { sunrise: P.when((val) => dayjs(val).isValid()) }, timeOfDayTimeToDisplay: "sunrise" },
                      (result) => <>{formatDate(result.timeGroup.sunrise, { time: true, timeZone: timeZone })}</>,
                    )
                    .with({ timeGroup: { sunset: P.when((val) => dayjs(val).isValid()) }, timeOfDayTimeToDisplay: "sunset" }, (result) => (
                      <>{formatDate(result.timeGroup.sunset, { time: true, timeZone: timeZone })}</>
                    ))
                    .otherwise(() => (
                      <>Not available</>
                    ))}
                </Text>
              </Flex>
            </Stack>
          </Box>
        </Flex>

        <Slider
          marks={sliderMarks}
          step={15 * 60 * 1000}
          max={timeSpan}
          color="brandBlue"
          mt="md"
          mb="xl"
          mx="xl"
          label={(value) => {
            const date = new Date(startTime.getTime() + value)
            return formatDate(date, { time: true, timeZone: timeZone, includeTimezone: false }).toLowerCase()
          }}
          onChange={setSliderValue}
          showLabelOnHover={false}
          thumbSize={32}
          thumbChildren={
            <span className="material-symbols-outlined" style={{ fontSize: "20px", fontWeight: 700 }}>
              flight_takeoff
            </span>
          }
        />

        <SafetyWeatherDetails
          taf={taf}
          metar={metar}
          weatherDetails={timeGroup.weatherDetails}
          loadingStatus="success"
          airport={airport.icaoId ?? airport.faaAirportId ?? ""}
          additionalWeatherDetails={timeGroup.additionalWeather}
          rawMode={rawMode}
          risks={timeGroup.weatherRisks}
          boxProps={{
            px: "xl",
          }}
        />

        {timeGroup.notamRisks.length <= 0 ? <Divider mx="xl" mt="xl" mb="lg" /> : <Space h="xl" />}

        <TimezoneContext.Provider value={{ departureTimeZone: timeZone }}>
          <RiskList
            notams={notams}
            loading={false}
            rawMode={rawMode}
            notamRisks={timeGroup.notamRisks}
            airsigmetRisks={[]}
            aerodromeRisks={[]}
            airSigmets={[]}
            pirepsRisks={[]}
            pireps={[]}
            airspace={[]}
          />
        </TimezoneContext.Provider>
        <Space h="xl" mt="xs" />
      </ScrollArea>
    </Flex>
  )
}
