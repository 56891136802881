import { Airport, AirportResult, SavedAirportIdTypeEnum } from "@soar/shared/types"
import { P, match } from "ts-pattern"

export function convertAirportToAirportResult(airport: Airport) {
  return {
    id: airport.id,
    name: airport.name,
    faa_airport_id: airport.faaAirportId,
    icao_id: airport.icaoId,
    city: airport.city,
    state_code: airport.stateCode,
    country_code: airport.countryCode,
    county: airport.county,
    elevation: airport.elevation,
    longitude: airport.longitude,
    latitude: airport.latitude,
    timeZone: airport.timeZone,
  } satisfies AirportResult
}
export function convertAirportResultToAirport(airport: AirportResult) {
  return {
    id: airport.id,
    name: airport.name,
    faaAirportId: airport.faa_airport_id,
    icaoId: airport.icao_id,
    city: airport.city,
    stateCode: airport.state_code,
    countryCode: airport.country_code,
    county: airport.county,
    elevation: airport.elevation,
    longitude: airport.longitude,
    latitude: airport.latitude,
    timeZone: airport.timeZone,
  } satisfies Airport
}

export function getAirportId(airport: Airport) {
  return getAirportIdAndType(airport).id
}

export function getAirportIdAndType(airport: Airport) {
  const airportIdentifier = match(airport)
    .with({ faaAirportId: P.string }, (result) => ({
      id: result.faaAirportId,
      type: SavedAirportIdTypeEnum.enum.FAA,
    }))
    .with({ icaoId: P.string }, (result) => ({
      id: result.icaoId,
      type: SavedAirportIdTypeEnum.enum.ICAO,
    }))
    .otherwise(() => null)

  if (airportIdentifier == null) {
    throw new Error("airport doesn't have identifier")
  }

  return airportIdentifier
}
