import { DashboardTimespan } from "@soar/shared/types"
import dayjs, { Dayjs } from "dayjs"

export function calculateBreakpoints(timespan: DashboardTimespan, dateStart: Dayjs) {
  const breakpoints: Dayjs[] = []
  const tickValues: string[] = []
  let xFormat = ""
  if (timespan === "today") {
    xFormat = "ha"
    for (let i = 0; i <= 24; i++) {
      const day = dateStart.add(i, "hours")
      breakpoints.push(day)
      if (i === 0 || i === 23) {
        tickValues.push(day.format(xFormat))
      } else {
        tickValues.push("")
      }
    }
  } else if (timespan === "week_1") {
    xFormat = "MMM D"
    for (let i = 1; i <= 8; i++) {
      const day = dateStart.add(i, "days")
      breakpoints.push(day)
      if (i === 1 || i === 7) {
        tickValues.push(day.format(xFormat))
      } else {
        tickValues.push("")
      }
    }
  } else if (timespan === "week_4") {
    xFormat = "MMM D"
    for (let i = 0; i <= 4; i++) {
      const day = dateStart.add(i, "weeks")
      breakpoints.push(day)
      if (i === 0 || i === 3) {
        tickValues.push(day.format(xFormat))
      } else {
        tickValues.push(" ")
      }
    }
  } else if (timespan === "mtd") {
    xFormat = "MMM D"
    const numDays = Math.abs(dateStart.diff(new Date(), "day"))

    for (let i = 0; i <= numDays + 1; i++) {
      const day = dateStart.add(i, "days")
      breakpoints.push(day)

      if (i === 0 || i === numDays) {
        tickValues.push(day.format(xFormat))
      } else {
        tickValues.push(" ")
      }
    }
  } else if (timespan === "ytd") {
    xFormat = "MMM"
    const numMonths = Math.abs(dateStart.diff(new Date(), "month"))
    const now = dayjs()
    for (let i = 0; i <= numMonths + 1; i++) {
      const day = dateStart.add(i, "months")
      if (day.isAfter(now)) {
        breakpoints.push(now)
      } else {
        breakpoints.push(day)
      }

      if (i === 0 || i === numMonths) {
        tickValues.push(day.format(xFormat))
      } else {
        tickValues.push(" ")
      }
    }
  }

  return {
    xFormat,
    breakpoints,
    tickValues,
  }
}
