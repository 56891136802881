import { ActionIcon, Box, Button, Drawer, Flex, ScrollArea, Stack, Text, TextInput, TextInputProps, createStyles } from "@mantine/core"
import { ChangeEvent, ForwardedRef, ReactNode, forwardRef, useEffect, useLayoutEffect, useRef } from "react"

import { useDrawerStyles } from "@soar/frontend/components"
import { SearchSelectClearButton } from "./clearButton"
import { ResultsDisplay } from "./results"

const useAirportInputDrawerStyles = createStyles((theme) => ({
  bottomBorder: {
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  hideOverflow: {
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  drawerPadding: {
    padding: "10px 15px",
  },
}))

// type Props<T extends {id: string | number | undefined}> =
function InputDrawerComponent<T extends { id: string | number | undefined }>(
  {
    value,
    opened,
    results = [],
    onClose = () => {},
    onSelect = () => {},
    onChange = () => {},
    placeholder,
    renderResult,
    textInputProps = {},
  }: {
    value?: string
    opened: boolean
    results?: T[]
    onClose?: () => void
    onChange?: (value: string) => void
    onSelect?: (result: T | undefined) => void
    renderResult: ({ data }: { data: T }) => ReactNode
    placeholder?: string
    textInputProps?: Partial<TextInputProps>
  },
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { classes: drawerClasses } = useDrawerStyles()
  const { classes: airportInputDrawerClasses, cx } = useAirportInputDrawerStyles()

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }
  const handleSelect = (result: T) => {
    onClose()
    onSelect(result)
  }
  const handleClear = () => {
    onChange("")
  }

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      classNames={{
        content: drawerClasses.drawerRadius,
        body: cx(airportInputDrawerClasses.hideOverflow, airportInputDrawerClasses.drawerPadding),
      }}
      position="bottom"
      size="95%"
      withCloseButton={false}
      title={null}
      keepMounted
    >
      <Flex direction="column" h="100%" mah="100%" className={airportInputDrawerClasses.hideOverflow}>
        <Flex justify="space-between" gap={5} mb="md" className={airportInputDrawerClasses.bottomBorder} pb="xs">
          <TextInput
            ref={ref}
            id="blah"
            // data-autofocus
            // autoFocus
            value={value}
            onChange={onTextChange}
            radius="xl"
            placeholder={placeholder}
            styles={{
              input: {
                backgroundColor: "#F1F6FE",
                border: "0",
              },
              root: {
                flexGrow: 1,
              },
            }}
            rightSection={<SearchSelectClearButton onClick={handleClear} />}
            {...textInputProps}
          />
          <Button variant="subtle" px="xs" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
        <Box style={{ flexGrow: 1, overflow: "hidden" }}>
          <ResultsDisplay
            results={results}
            //@ts-ignore
            onSelect={handleSelect}
            renderResult={renderResult}
          />
        </Box>
      </Flex>
    </Drawer>
  )
}

// biome-ignore lint/complexity/noBannedTypes: this is a weird thing that fixes TS/React
function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  return forwardRef(render) as any
}
export const InputDrawer = fixedForwardRef(InputDrawerComponent)
