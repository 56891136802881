import { Badge, Box, Button, Center, Flex, Loader, Stack, Text, UnstyledButton } from "@mantine/core"
import { Airport, SchedulingFlightLeg } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import { match } from "ts-pattern"
import { CustomLink } from "../customLink"
import { DepartureDestinationDisplay } from "../safety"
import { StatusDisplay } from "../statusDisplay"
import { SectionHeader } from "./SectionHeader"

function PendingReviewFlightLegCard({
  airport,
  flightLeg,
  url,
}: {
  airport: Airport
  flightLeg: SchedulingFlightLeg
  url: string
}) {
  return (
    <CustomLink href={url} passHref legacyBehavior>
      <UnstyledButton component="a">
        <Flex justify="space-between">
          <Box>
            <Flex>
              <Box c="gray.7">
                <DepartureDestinationDisplay from={flightLeg.departureAirport} to={flightLeg.destinationAirport} size="xs" />
              </Box>
              {flightLeg.flightIdentifier != null && <Badge>{flightLeg.flightIdentifier}</Badge>}
            </Flex>
            <Text c="dimmed" fz="sm">
              {flightLeg.crew.map((crewMember) => `${crewMember.firstName} ${crewMember.lastName}`).join(", ")}
            </Text>
          </Box>
          <Box fz="xs" fw={600} c="gray.6" ta="right">
            <Text ta="right">{formatDate(flightLeg.departAt, { date: { format: "long" }, timeZone: airport.timeZone ?? undefined })}</Text>
            <Text>{formatDate(flightLeg.departAt, { time: true, timeZone: airport.timeZone ?? undefined })}</Text>
          </Box>
        </Flex>
      </UnstyledButton>
    </CustomLink>
  )
}

export function PendingReviewsSection({
  flightLegWithAirports,
  organizationId,
  viewMoreUrl,
  makeFlightLegUrl,
  isLoading,
}: {
  flightLegWithAirports: Array<{ flightLeg: SchedulingFlightLeg; airport: Airport }>
  organizationId?: string
  viewMoreUrl: string
  makeFlightLegUrl: (flightLegId: string) => string
  isLoading: boolean
}) {
  return (
    <Flex direction="column" justify="space-between" h="100%">
      <Box>
        <SectionHeader title="Pending reviews" timePeriod="All time" />
        {match({ isLoading, flightLegWithAirports })
          .with({ isLoading: true }, () => (
            <Center py="xl" mt="xl">
              <Loader variant="bars" />
            </Center>
          ))
          .when(
            ({ flightLegWithAirports }) => flightLegWithAirports.length > 0,
            ({ flightLegWithAirports }) => (
              <Stack>
                {flightLegWithAirports.map(({ airport, flightLeg }) => {
                  const url = makeFlightLegUrl(flightLeg.id)
                  return <PendingReviewFlightLegCard key={flightLeg.id} airport={airport} flightLeg={flightLeg} url={url} />
                })}
              </Stack>
            ),
          )
          .otherwise(() => (
            <Box py={36}>
              <StatusDisplay align="flex-start" label="No data for this time period" />
            </Box>
          ))}
      </Box>
      <Flex justify="flex-end" pt="xs">
        <CustomLink href={viewMoreUrl} passHref legacyBehavior>
          <Button component="a" variant="subtle" px={0}>
            View more
          </Button>
        </CustomLink>
      </Flex>
    </Flex>
  )
}
