import { Box, ScrollArea, Stack, UnstyledButton } from "@mantine/core"
import { ReactEventHandler, ReactNode } from "react"

export function ResultsDisplay<T extends { id: string | number | undefined }>({
  results = [],
  renderResult,
  onSelect = () => {},
  selectedIndex = -1,
}: {
  results?: T[]
  renderResult: ({ data }: { data: T }) => ReactNode
  onSelect?: (selectedData: T) => void
  selectedIndex?: number
}) {
  // const { classes } = useAirportInputStyles()
  return (
    <ScrollArea h="100%">
      {
        <Stack
          spacing={0}
          // className={classes.wrapperRadius}
          styles={(theme) => ({
            root: {
              borderRadius: theme.radius.lg,
            },
          })}
        >
          {results.map((result, index) => {
            const onClick = () => {
              console.log("selected: ", result)
              onSelect(result)
            }
            return (
              <ResultDisplay key={result.id ?? index} selected={index === selectedIndex} onClick={onClick}>
                {renderResult({ data: result })}
              </ResultDisplay>
            )
          })}
        </Stack>
      }
    </ScrollArea>
  )
}
function ResultDisplay({
  selected,
  onClick,
  children,
}: {
  selected: boolean
  onClick: () => void
  children: ReactNode
}) {
  const handleClick: ReactEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    onClick()
  }
  return (
    <UnstyledButton onClick={handleClick} bg={selected ? "brandBlue.1" : undefined}>
      {children}
    </UnstyledButton>
  )
}
