import { Box, Flex, Paper, Stack, Text, UnstyledButton } from "@mantine/core"
import type { Aircraft, AircraftWithLocation } from "@soar/shared/types"
import { ReactNode } from "react"

export function AircraftCard({
  aircraft,
  onEditClick = () => {},
  children,
}: {
  aircraft: AircraftWithLocation | Aircraft
  onEditClick?: () => void
  children?: ReactNode
}) {
  /*
  const fields: string[] = []
  if (aircraft.airspeed != null) {
    fields.push(`${aircraft.airspeed} TAS`)
  }

  if (aircraft.fuelBurn != null) {
    fields.push(`${aircraft.fuelBurn} fuel / hr`)
  }

  if (aircraft.maxWeight != null) {
    fields.push(`${aircraft.maxWeight} max payload`)
  }

  if (aircraft.maxWindsThreshold != null) {
    fields.push(`${aircraft.maxWindsThreshold} max winds`)
  }

  const fieldsDisplay: ReactNode[] = []
  for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++) {
    const field = fields[fieldIndex]
    fieldsDisplay.push(<Text key={fieldIndex}>{field}</Text>)
    if (fieldIndex < fields.length - 1) {
      fieldsDisplay.push(<IconPointFilled key={`separator-${fieldIndex}`} height={12} width={12} />)
    }
  }
  */

  return (
    <UnstyledButton
      onClick={onEditClick}
      sx={(theme) => ({
        "& + &": {
          borderTop: `thin solid ${theme.colors.gray[3]}`,
        },
      })}
    >
      <Paper py="md" px="md" radius={0}>
        <Stack c="gray.6" fw={600} spacing={2}>
          <Flex justify="space-between" fw={700} fz="lg">
            <Text c="black">{aircraft.tailNumber}</Text>
            <Box>{children}</Box>
          </Flex>
          {aircraft.airspeed != null && <Text>{aircraft.airspeed} TAS</Text>}
          {aircraft.fuelBurn != null && <Text>{aircraft.fuelBurn} fuel / hr</Text>}
        </Stack>
      </Paper>
    </UnstyledButton>
  )
}
