import { z } from "zod"

const LocalStorageValidator = z.object({
  organizationId: z.string().nullish(),
  locationIds: z.string().array().nullish(),
  locationId: z.string().nullish(),
  profile: z
    .object({
      type: z.enum(["user", "organization"]),
      id: z.string(),
    })
    .nullish(),
})

type LocalStoragePayload = z.infer<typeof LocalStorageValidator>

const EMPTY_STORAGE_PAYLOAD = {} as const

const LOCAL_STORAGE_KEY = "soar-storage" as const

function checkForLocalStorage() {
  if (window?.localStorage == null) {
    throw "no localStorage found"
  }
}

export function grabFromLocalStorage(userId: string): LocalStoragePayload | null {
  try {
    checkForLocalStorage()
    const key = `${LOCAL_STORAGE_KEY}:${userId}`
    const contents = localStorage.getItem(key)

    if (contents == null) {
      throw `nothing stored in ${key}`
    }

    const parsedContents = JSON.parse(contents)

    return LocalStorageValidator.parse(parsedContents)
  } catch {
    return null
  }
}

export function storeItems(userId: string, payload: Partial<LocalStoragePayload>): boolean {
  try {
    checkForLocalStorage()

    const existingContents = grabFromLocalStorage(userId)
    const baseStoragePayload = existingContents == null ? EMPTY_STORAGE_PAYLOAD : existingContents
    const storagePayload = { ...baseStoragePayload, ...payload }

    LocalStorageValidator.parse(storagePayload)

    const key = `${LOCAL_STORAGE_KEY}:${userId}`

    localStorage.setItem(key, JSON.stringify(storagePayload))

    return true
  } catch (e) {
    return false
  }
}
