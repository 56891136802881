import { Profile } from "@soar/shared/types"
import { atom } from "jotai"
import { storeItems } from "./localStorage"
import { currentUserAtom } from "./userAtom"

export const profileAtom = atom<Profile | null>(null)
export const profileAtomWithStorage = atom(
  (get) => get(profileAtom),
  (get, set, payload: Profile | null) => {
    set(profileAtom, payload)
    const user = get(currentUserAtom)

    // store in local storage
    const storeValue =
      payload == null
        ? undefined
        : {
            type: payload.type,
            id: payload.type === "user" ? payload.user.id : payload.organization.id,
          }
    if (user != null) {
      const stored = storeItems(user.id, { profile: storeValue })
    }
  },
)
