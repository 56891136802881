import { ActionIcon, Box, Button, Drawer, Flex, ScrollArea, Stack, Text, TextInput, TextInputProps, createStyles } from "@mantine/core"
import { ChangeEvent, forwardRef, useEffect, useLayoutEffect, useRef } from "react"

import { useFocusTrap, useMergedRef } from "@mantine/hooks"
import { useDrawerStyles } from "@soar/frontend/components"
import { AirportResult, AirportSchema } from "@soar/shared/types"
import { AirportInputClearButton } from "./airportInput/clearButton"
import { AirportInputResultsDisplay, AirportResultDisplay, NavResultDisplay } from "./results"
import { NavResult } from "./types"

const useAirportInputDrawerStyles = createStyles((theme) => ({
  bottomBorder: {
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
  },
  hideOverflow: {
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  drawerPadding: {
    padding: "10px 15px",
  },
}))

type Props = {
  value?: string
  opened: boolean
  airportResults?: AirportResult[]
  navResults?: NavResult[]
  onClose?: () => void
  onChange?: (value: string) => void
  onSelect?: (airport: AirportResult | undefined) => void
  placeholder?: string
  textInputProps?: Partial<TextInputProps>
}

export const AirportInputDrawer = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      opened,
      airportResults = [],
      navResults = [],
      onClose = () => {},
      onSelect = () => {},
      onChange = () => {},
      placeholder,
      textInputProps = {},
    },
    ref,
  ) => {
    const { classes: drawerClasses } = useDrawerStyles()
    const { classes: airportInputDrawerClasses, cx } = useAirportInputDrawerStyles()

    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    }
    const handleSelect = (airport: AirportResult) => {
      onClose()
      onSelect(airport)
    }
    const handleClear = () => {
      onChange("")
    }

    return (
      <Drawer
        opened={opened}
        onClose={onClose}
        classNames={{
          content: drawerClasses.drawerRadius,
          body: cx(airportInputDrawerClasses.hideOverflow, airportInputDrawerClasses.drawerPadding),
        }}
        position="bottom"
        size="95%"
        withCloseButton={false}
        title={null}
        keepMounted
      >
        <Flex direction="column" h="100%" mah="100%" className={airportInputDrawerClasses.hideOverflow}>
          <Flex justify="space-between" gap={5} mb="md" className={airportInputDrawerClasses.bottomBorder} pb="xs">
            <TextInput
              ref={ref}
              id="blah"
              // data-autofocus
              // autoFocus
              value={value}
              onChange={onTextChange}
              radius="xl"
              placeholder={placeholder}
              styles={{
                input: {
                  backgroundColor: "#F1F6FE",
                  border: "0",
                },
                root: {
                  flexGrow: 1,
                },
              }}
              rightSection={<AirportInputClearButton onClick={handleClear} />}
              {...textInputProps}
            />
            <Button variant="subtle" px="xs" onClick={onClose}>
              Cancel
            </Button>
          </Flex>
          <Box style={{ flexGrow: 1, overflow: "hidden" }}>
            <AirportInputResultsDisplay
              airportResults={airportResults}
              navResults={navResults}
              onSelect={handleSelect}
              showFavoriteAirports={value == null || value.length === 0}
            />
          </Box>
        </Flex>
      </Drawer>
    )
  },
)
