import {
  FlightStage,
  SafetyReportFactorCategory,
  SafetyReportFactorCategoryEnum,
  SafetyReportFactorType,
  SafetyReportStatus,
} from "@soar/shared/types"

export type SafetyReportFactorConfigDetails = {
  label: string
  categories: SafetyReportFactorCategory[]
}

export const SafetyReportFactorConfig: Record<SafetyReportFactorType, SafetyReportFactorConfigDetails> = {
  weather_condition: {
    label: "Weather condition",
    categories: [],
  },
  pilot_error: {
    label: "Pilot error",
    categories: [],
  },
  mechanical_failure: {
    label: "Mechanical failure",
    categories: [],
  },
  atc_issue: {
    label: "ATC issue",
    categories: [],
  },
  fuel_management: {
    label: "Fuel management",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  runway_incursion: {
    label: "Runway incursion",
    categories: [],
  },
  bird_strike: {
    label: "Bird strike",
    categories: [],
  },
  procedure_deviation: {
    label: "Procedure deviation",
    categories: [],
  },
  emergency_readiness: {
    label: "Emergency readiness",
    categories: [],
  },
  crew_fatigue: {
    label: "Crew fatigue",
    categories: [],
  },
  training_gap: {
    label: "Training gap",
    categories: [],
  },
  communication_failure: {
    label: "Communication failure",
    categories: [],
  },
  security_threat: {
    label: "Security threat",
    categories: [],
  },
  cargo_handling: {
    label: "Cargo handling",
    categories: [],
  },
  navigation_error: {
    label: "Navigation error",
    categories: [],
  },
  ground_incident: {
    label: "Ground incident",
    categories: [],
  },
  regulatory_compliance: {
    label: "Regulatory compliance",
    categories: [],
  },
  passenger_safety: {
    label: "Passenger safety",
    categories: [],
  },
  medical_emergency: {
    label: "Medical emergency",
    categories: [],
  },
  maintenance_issue: {
    label: "Maintenance issue",
    categories: [],
  },

  wildlife: {
    label: "Wildlife",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  weather: {
    label: "Weather",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  turbulence: {
    label: "Turbulence",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  system_malfunction: {
    label: "System malfunction",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  flight_planning: {
    label: "Flight planning",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  congestion: {
    label: "Congestion",
    categories: [SafetyReportFactorCategoryEnum.enum.flight, SafetyReportFactorCategoryEnum.enum.ground],
  },
  human_error: {
    label: "Human error",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  fatigue: {
    label: "Fatigue",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  navigation: {
    label: "Navigation",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  maintenance: {
    label: "Maintenance",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  foreign_object_debris: {
    label: "Foreign object debris (FOD)",
    categories: [SafetyReportFactorCategoryEnum.enum.flight, SafetyReportFactorCategoryEnum.enum.ground],
  },
  weight_and_balance: {
    label: "Weight and balance",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  charts_and_data: {
    label: "Charts and data",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  communication: {
    label: "Communication",
    categories: [SafetyReportFactorCategoryEnum.enum.flight, SafetyReportFactorCategoryEnum.enum.ground],
  },
  situational_awareness: {
    label: "Situational awareness",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  uncontrolled_airspace: {
    label: "Uncontrolled airspace",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  drones: {
    label: "Drones",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  distractions: {
    label: "Distractions",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },
  visibility: {
    label: "Visibility",
    categories: [SafetyReportFactorCategoryEnum.enum.flight],
  },

  hazmat: {
    label: "Hazmat",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  fueling: {
    label: "Fueling",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  signage: {
    label: "Signage",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  ground_training: {
    label: "Ground training",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  lighting: {
    label: "Lighting",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  equipment_malfunction: {
    label: "Equipment malfunction",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  slippery_surfaces: {
    label: "Slippery surfaces",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  obstructions: {
    label: "Obstructions",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  procedures: {
    label: "Procedures",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  deicing: {
    label: "Deicing",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  towing: {
    label: "Towing",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  ladders_and_stands: {
    label: "Ladders and stands",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  exits_and_access: {
    label: "Exits and access",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  unauthorized_personnel: {
    label: "Unauthorized personnel",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  surface_damage: {
    label: "Surface damage",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  chocks_and_brakes: {
    label: "Chocks and brakes",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
  loading: {
    label: "Loading",
    categories: [SafetyReportFactorCategoryEnum.enum.ground],
  },
}

export type SafetyReportStatusConfigDetails = {
  label: string
  colorType: "mantine" | "custom"
  color: string
}

export const SafetyReportStatusConfig: Record<SafetyReportStatus, SafetyReportStatusConfigDetails> = {
  created: {
    label: "Open",
    colorType: "mantine",
    color: "dark.8",
  },
  inProgress: {
    label: "In progress",
    colorType: "mantine",
    color: "yellow.8",
  },
  resolved: {
    label: "Resolved",
    colorType: "mantine",
    color: "brandBlue.8",
  },
}

type FlightStageConfigDetails = {
  label: string
  appliesTo: {
    departure: boolean
    destination: boolean
  }
}

export const FlightStageConfig: Record<FlightStage, FlightStageConfigDetails> = {
  pre_taxi: {
    label: "Pre-taxi",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  taxi_out: {
    label: "Taxi out",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  takeoff: {
    label: "Takeoff",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  climb: {
    label: "Climb",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  cruise: {
    label: "Cruise",
    appliesTo: {
      departure: true,
      destination: true,
    },
  },
  descent: {
    label: "Descent",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  approach: {
    label: "Approach",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  landing: {
    label: "Landing",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  taxi_in: {
    label: "Taxi out",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  shutdown: {
    label: "Shutdown",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
}
