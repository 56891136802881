import { Badge, Box, Flex, Stack, Text } from "@mantine/core"
import { organizationsForUserAtom } from "@soar/frontend/contexts"
import { SchedulingFlightLeg, SchedulingFlightLegSearchDocument } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import { useAtomValue } from "jotai"
import { ReactNode, useMemo } from "react"
import { P, match } from "ts-pattern"
import { z } from "zod"
import { DepartureDestinationDisplay } from "../safety"

export function FlightLegResultCard({
  flightLeg,
  children,
  rightSection,
  leftSection,
}: {
  flightLeg: SchedulingFlightLeg | SchedulingFlightLegSearchDocument
  children?: ReactNode
  rightSection?: ReactNode
  leftSection?: ReactNode
}) {
  const departAt = useMemo(() => z.coerce.date().parse(flightLeg.departAt), [flightLeg])

  const organizationsForUser = useAtomValue(organizationsForUserAtom)

  const organization = useMemo(() => {
    return organizationsForUser?.find((org) => org.id === flightLeg.organizationId)
  }, [flightLeg, organizationsForUser])

  return (
    <Flex>
      <Box>{leftSection}</Box>
      <Flex justify="space-between" style={{ flexGrow: 1 }}>
        <Box>
          <Flex align="center" gap="xs">
            <DepartureDestinationDisplay from={flightLeg.departureAirport} to={flightLeg.destinationAirport} size="sm" />
            {flightLeg.flightIdentifier != null && (
              <Badge color="gray.6" size="xs" variant="filled" radius="sm">
                {flightLeg.flightIdentifier}
              </Badge>
            )}
          </Flex>
          <Stack c="dimmed" mt={4} spacing={2} fw={600}>
            {flightLeg.aircraftTailNumber != null && flightLeg.aircraftTailNumber.length > 0 && <Text>{flightLeg.aircraftTailNumber}</Text>}
            <Text>
              {flightLeg.crew
                .map((crew) =>
                  match(crew)
                    .with({ firstName: P.nonNullable, lastName: P.nonNullable }, ({ firstName, lastName }) => `${firstName} ${lastName}`)
                    .with({ email: P.nonNullable }, ({ email }) => email)
                    .otherwise(() => "Unknown"),
                )
                .join(", ")}
            </Text>
            <Text>{formatDate(departAt, { date: { format: "long" }, time: true, includeTimezone: true })}</Text>
          </Stack>
        </Box>
        <Box>
          <Box c="dimmed" fw={600}>
            {organization?.name}
          </Box>
          {children}
        </Box>
      </Flex>
      <Box>{rightSection}</Box>
    </Flex>
  )
}
