import { z } from "zod"

export const AirportResultSchema = z.object({
  id: z.string().ulid(),
  name: z.string(),
  faa_airport_id: z.string().nullish(),
  icao_id: z.string().nullish(),
  city: z.string().nullish(),
  state_code: z.string().nullish(),
  country_code: z.string().nullish(),
  county: z.string().nullish(),
  elevation: z.number(),
  longitude: z.number(),
  latitude: z.number(),
  timeZone: z.string().nullish(),
})

export type AirportResult = z.infer<typeof AirportResultSchema>

export const AirportSchema = z.object({
  id: z.string().ulid(),
  name: z.string(),
  faaAirportId: z.string().nullish(),
  icaoId: z.string().nullish(),
  city: z.string().nullish(),
  stateCode: z.string().nullish(),
  countryCode: z.string().nullish(),
  county: z.string().nullish(),
  elevation: z.number(),
  longitude: z.number(),
  latitude: z.number(),
  timeZone: z.string().nullish(),
})

export type Airport = z.infer<typeof AirportSchema>

export const SavedAirportIdTypeEnum = z.enum(["FAA", "ICAO"])
export type SavedAirportIdType = z.infer<typeof SavedAirportIdTypeEnum>

export const SavedAirportSchema = z.object({
  id: z.string(),
  airportId: z.string(),
  airportIdType: SavedAirportIdTypeEnum,
  userId: z.string(),
})
export type SavedAirport = z.infer<typeof SavedAirportSchema>
