import { Box, Flex, ScrollArea, Stack, Text, UnstyledButton } from "@mantine/core"
import { Airport, AirportResult } from "@soar/shared/types"
import { convertAirportToAirportResult } from "@soar/shared/utils"
import { useAirportInputStyles } from "./styles"
import { NavResult } from "./types"

export function AirportInputResultsDisplay({
  airportResults = [],
  navResults = [],
  onSelect = () => {},
  selectedIndex = -1,
  favoriteAirports,
  showFavoriteAirports = false,
}: {
  airportResults?: AirportResult[]
  navResults?: NavResult[]
  onSelect?: (airport: AirportResult) => void
  selectedIndex?: number
  favoriteAirports?: Airport[]
  showFavoriteAirports?: boolean
}) {
  const { classes } = useAirportInputStyles()
  return (
    <ScrollArea h="100%">
      {
        // biome-ignore lint: temporary
        showFavoriteAirports && false ? (
          <FavoriteAirportsDisplay favoriteAirports={favoriteAirports} onSelect={onSelect} selectedIndex={selectedIndex} />
        ) : (
          <Stack
            spacing={0}
            className={classes.wrapperRadius}
            styles={(theme) => ({
              root: {
                borderRadius: theme.radius.lg,
              },
            })}
          >
            {airportResults.map((result, index) => (
              <AirportResultDisplay key={result.id} airportResult={result} selected={index === selectedIndex} onClick={onSelect} />
            ))}
            {navResults.map((result, index) => (
              <NavResultDisplay key={result.id} navResult={result} />
            ))}
          </Stack>
        )
      }
    </ScrollArea>
  )
}

export function FavoriteAirportsDisplay({
  favoriteAirports = [],
  selectedIndex = -1,
  onSelect = () => {},
}: {
  favoriteAirports?: Airport[]
  selectedIndex?: number
  onSelect?: (airport: AirportResult) => void
}) {
  const hasFavoriteAirports = favoriteAirports.length > 0
  if (hasFavoriteAirports) {
    const airportResults = favoriteAirports.map(convertAirportToAirportResult)
    return (
      <>
        <Text fz="lg" fw={700} mb="md">
          Favorite Airports
        </Text>
        <Stack spacing="xs">
          {airportResults.map((airport, index) => (
            <AirportResultDisplay key={airport.id} airportResult={airport} selected={index === selectedIndex} onClick={onSelect} />
          ))}
        </Stack>
      </>
    )
  } else {
    return (
      <Box>
        <Text fz="lg" fw={700} mb="md">
          Favorite Airports
        </Text>
        <Text color="dimmed" italic>
          Add favorite airports from your homepage.
        </Text>
      </Box>
    )
  }
}

export function AirportResultDisplay({
  airportResult,
  selected = false,
  onClick = () => {},
}: {
  airportResult: AirportResult
  selected?: boolean
  onClick?: (airport: AirportResult) => void
}) {
  const { classes, cx } = useAirportInputStyles()
  const classNames = cx(classes.airport, { [classes.selectedAirport]: selected })

  let locationName = airportResult.city
  if (airportResult.state_code != null) {
    locationName = `${locationName}, ${airportResult.state_code}`
  } else if (airportResult.country_code != null) {
    locationName = `${locationName}, ${airportResult.country_code}`
  }
  const identifier = airportResult.icao_id ?? airportResult.faa_airport_id

  const handleClick = () => {
    onClick(airportResult)
  }

  return (
    <UnstyledButton className={classNames} p="0.35rem" onClick={handleClick} tabIndex={-1}>
      <Text>
        <Text span fw={700} mr={2}>
          {" "}
          {identifier}{" "}
        </Text>
        <Text fz="sm" span className="dimmed" color="dimmed">
          {" "}
          {airportResult.name}{" "}
        </Text>
      </Text>
      <Flex>
        <Text fz="xs" className="dimmed" color="dimmed">
          {locationName}
        </Text>
      </Flex>
    </UnstyledButton>
  )
}

export function NavResultDisplay({
  navResult,
  selected = false,
  onClick = () => {},
}: {
  navResult: NavResult
  selected?: boolean
  onClick?: (airport: NavResult) => void
}) {
  const { classes, cx } = useAirportInputStyles()
  const classNames = cx(classes.airport, { [classes.selectedAirport]: selected })

  const locationNames = [navResult.name ?? navResult.city, navResult.state_code, navResult.country_code].filter((part) => part != null)
  const locationName = locationNames.join(", ")

  const handleClick = () => {
    onClick(navResult)
  }

  return (
    <UnstyledButton className={classNames} p="0.35rem" onClick={handleClick} tabIndex={-1}>
      <Text>
        <Text span fw={700}>
          {" "}
          {navResult.identifier}{" "}
        </Text>
        <Text span> - </Text>
        <Text fz="sm" span>
          {" "}
          {navResult.type}{" "}
        </Text>
      </Text>
      <Flex>
        <Text fz="xs">{locationName}</Text>
      </Flex>
    </UnstyledButton>
  )
}
