import { Box, Button, Center, Flex, Loader, Stack, Text, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { DashboardTimespan, FlightLegStatusEnum, SchedulingFlightLeg } from "@soar/shared/types"
import { match } from "ts-pattern"
import { ModalOrDrawer } from "../modalOrDrawer"
import { StatusDisplay } from "../statusDisplay"
import { SectionHeader } from "./SectionHeader"

import dayjs, { Dayjs } from "dayjs"
import { useMemo } from "react"

function calculateFlightLegCompletionsByPilot(flightLegs: SchedulingFlightLeg[]) {
  const userMap = new Map<string, { id: string; name: string; complete: number; incomplete: number }>()
  const endDate = dayjs().endOf("day").toDate()
  for (const flightLeg of flightLegs) {
    if (flightLeg.departAt > endDate) {
      continue
    }
    const isComplete = flightLeg.status === FlightLegStatusEnum.enum.on_hold || flightLeg.status === FlightLegStatusEnum.enum.approved
    const users = flightLeg.crew.map((crewMember) => {
      const id = crewMember.userId ?? crewMember.externalId
      const name = `${crewMember.firstName} ${crewMember.lastName}`
      return { id, name }
    })

    for (const user of users) {
      const currentCount = userMap.get(user.id) ?? {
        id: user.id,
        name: user.name,
        complete: 0,
        incomplete: 0,
      }
      if (isComplete) {
        currentCount.complete = currentCount.complete + 1
      } else {
        currentCount.incomplete = currentCount.incomplete + 1
      }
      userMap.set(user.id, currentCount)
    }
  }
  return [...userMap.values()].sort((a, b) => b.complete - a.complete)
}

function PilotCompletionRow({
  completionRecord,
}: {
  completionRecord: ReturnType<typeof calculateFlightLegCompletionsByPilot>[number]
}) {
  const total = completionRecord.complete + completionRecord.incomplete
  const percentage = Math.floor((total > 0 ? completionRecord.complete / total : 0) * 100)
  return (
    <Box>
      <Text c="gray.7" mb={5} fw={600} fz="sm">
        {completionRecord.name}
      </Text>
      <Flex
        align="stretch"
        h={35}
        ta="center"
        fz="sm"
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          overflow: "hidden",
        })}
      >
        <Flex w={`${percentage}%`} bg="brandBlue.7" align="center" justify="center" c="white">
          {percentage !== 0 && completionRecord.complete}
        </Flex>
        <Flex
          align="center"
          justify="center"
          bg="gray.3"
          style={{
            flexGrow: 1,
          }}
        >
          {percentage < 100 && completionRecord.incomplete}
        </Flex>
      </Flex>
    </Box>
  )
}

export function AssessmentsByPilotSection({
  data = [],
  dateKey,
  startDate,
  timespanLabel,
  isLoading,
}: {
  dateKey: DashboardTimespan
  data?: SchedulingFlightLeg[]
  startDate: Dayjs
  timespanLabel: string
  isLoading: boolean
}) {
  const { completions } = useMemo(() => {
    const completionsByPilot = calculateFlightLegCompletionsByPilot(data)

    return {
      completions: completionsByPilot,
    }
  }, [data])

  const [modalState, modalHandlers] = useDisclosure(false)

  return (
    <Flex h="100%" direction="column" justify="space-between">
      <Box>
        <SectionHeader title="Assessments completed by pilot" timePeriod={timespanLabel} />
        <Stack w="100%">
          {match({ completions, isLoading })
            .with({ isLoading: true }, () => (
              <Center py="xl" mt="xl">
                <Loader variant="bars" mt="xl" />
              </Center>
            ))
            .when(
              ({ completions }) => completions.length === 0,
              () => (
                <Box py={36}>
                  <StatusDisplay align="flex-start" label="No assessments for this time period" />
                </Box>
              ),
            )
            .otherwise((matchedValue) =>
              matchedValue.completions.slice(0, 6).map((completion) => {
                return <PilotCompletionRow completionRecord={completion} key={completion.id} />
              }),
            )}
        </Stack>
      </Box>
      <Flex justify="flex-end" pt="xs">
        {completions.length > 0 && (
          <Button onClick={modalHandlers.open} variant="subtle" px={0}>
            View more
          </Button>
        )}
      </Flex>
      <ModalOrDrawer opened={modalState} onClose={modalHandlers.close} title={""}>
        <Title order={3} mb="md">
          Assessments completed by pilot
        </Title>
        <Stack>
          {completions.map((completion) => {
            return <PilotCompletionRow completionRecord={completion} key={completion.id} />
          })}
        </Stack>
      </ModalOrDrawer>
    </Flex>
  )
}
