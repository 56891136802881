import { AircraftWithOrgDetails, AircraftWithOrgDetailsSchema, SafetyInitialValues } from "@soar/shared/types"
import { initializeOrgName } from "@soar/shared/utils"
import { useMemo, useState } from "react"
import { useUser } from "./userContext"

type UseAircraftInitialValues = Pick<SafetyInitialValues, "organizationId" | "aircraftTailNumber">

export function useAircraft() {
  const { aircraft, refetchAircraft, isAircraftLoading, isAircraftSuccess } = useUser()
  const [initialValues, setInitialValues] = useState<UseAircraftInitialValues>({})

  const { aircraftToSelect, matchedAircraft } = useMemo(() => {
    if (aircraft == null || aircraft.length === 0) {
      return { aircraftToSelect: undefined, matchedAircraft: false }
    }

    let aircraftToSelect: AircraftWithOrgDetails | undefined
    let matchedAircraft = false
    const firstAircraft = AircraftWithOrgDetailsSchema.parse(aircraft[0])

    if (initialValues?.organizationId != null && initialValues?.aircraftTailNumber != null) {
      const matchingAircraft = aircraft.find(
        (aircraftFromDb) =>
          aircraftFromDb.tailNumber.toUpperCase() === initialValues.aircraftTailNumber &&
          aircraftFromDb.organizationId === initialValues.organizationId,
      )
      if (matchingAircraft != null) {
        aircraftToSelect = matchingAircraft
        matchedAircraft = true
      }
    }

    if (aircraftToSelect == null) {
      aircraftToSelect = firstAircraft
    }

    return { aircraftToSelect, matchedAircraft }
  }, [aircraft, initialValues])

  const aircraftOptions = useMemo(() => {
    if (aircraft == null) {
      return []
    }
    const orgMap = new Map<string, AircraftWithOrgDetails[]>()

    for (const currentAircraft of aircraft) {
      let orgName = currentAircraft.organizationShortName
      if (orgName == null && currentAircraft.organizationName != null) {
        orgName = initializeOrgName(currentAircraft.organizationName)
      }
      if (orgName == null) {
        orgName = ""
      }

      const currentOrgAircrafts = orgMap.get(orgName) ?? []
      orgMap.set(orgName, [...currentOrgAircrafts, currentAircraft])
    }

    const mappedAircraftOptions = [...orgMap.keys()]
      .sort((a, b) => a.localeCompare(b))
      .flatMap((orgName) => {
        const aircraftForOrg = orgMap.get(orgName)!
        return aircraftForOrg
          .map((currentAircraft) => {
            return {
              label: `${currentAircraft.tailNumber}${orgName != null && orgName.length > 0 ? ` - ${orgName}` : ""}`,
              value: currentAircraft.id,
              tailNumber: currentAircraft.tailNumber,
              organizationId: currentAircraft.organizationId,
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      })
    return mappedAircraftOptions
  }, [aircraft])

  //const [createAircraftModalState, createAircraftModalHandlers] = useDisclosure(false)
  return {
    aircraftForUser: aircraft,
    aircraftToSelect,
    matchedAircraft,
    setInitialValues,
    aircraftOptions,
    refetchAircraft,
    isAircraftLoading,
    isAircraftSuccess,
  }
}
