// import { atomWithStorage, RESET } from 'jotai/utils'
import { Organization, UserRole } from "@soar/shared/types"
import { atom } from "jotai"
import { storeItems } from "./localStorage"
import { currentUserAtom } from "./userAtom"

export type OrganizationWithRoles = {
  organization: Organization
  roles: UserRole[]
}

export const organizationAtom = atom<Organization | null>(null)
export const organizationsWithRolesForUserAtom = atom<OrganizationWithRoles[] | null>(null)
export const organizationsForUserAtom = atom((get) => {
  const orgsWithRoles = get(organizationsWithRolesForUserAtom)
  return orgsWithRoles?.map((orgWithRoles) => orgWithRoles.organization)
})
export const organizationsSelectableForUserAtom = atom((get) => {
  const orgsWithRoles = get(organizationsWithRolesForUserAtom)
  return (
    (orgsWithRoles ?? [])
      /*
    .filter((orgWithRoles) => {
      const roleSet = new Set(orgWithRoles.roles)
      return roleSet.has(UserRole.enum.ADMIN) || roleSet.has(UserRole.enum.SUPERADMIN) || roleSet.has(UserRole.enum.STAFF_PILOT)
    })
    */
      .map((orgWithRoles) => orgWithRoles.organization)
  )
})
export const organizationAtomWithStorage = atom(
  (get) => get(organizationAtom),
  (get, set, payload: Organization | null) => {
    set(organizationAtom, payload)
    const currentUser = get(currentUserAtom)

    // store in local storage
    const storeValue = payload == null ? undefined : payload.id
    if (currentUser != null) {
      const stored = storeItems(currentUser.id, { organizationId: storeValue })
    }
  },
)
