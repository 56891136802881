import { TextInputProps } from "@mantine/core"
import { SchedulingFlightLegSearchDocument } from "@soar/shared/types"
import { getFlightLegSearchResultsTypesense } from "@soar/shared/utils"
import { SearchSelect } from "../searchSelect"
import { FlightLegResultCard } from "./resultCard"

async function onQueryFlightLegs(query: string, key: string) {
  const response = await getFlightLegSearchResultsTypesense(query, key)
  return response.results
}

function FlightLegResult({
  data,
}: {
  data: SchedulingFlightLegSearchDocument
}) {
  return <FlightLegResultCard flightLeg={data} />
}

export function FlightLegSelect({
  onSelect = () => {},
  textInputProps,
}: {
  onSelect?: (flightLeg: SchedulingFlightLegSearchDocument | undefined) => void
  textInputProps?: Partial<TextInputProps>
}) {
  return (
    <SearchSelect
      cacheKey="flightLegs"
      onQuery={onQueryFlightLegs}
      renderResult={FlightLegResult}
      onSelect={onSelect}
      textInputProps={textInputProps}
      selectOnBlur={false}
    />
  )
}
