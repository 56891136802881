// import { atomWithStorage, RESET } from 'jotai/utils'
import { Location } from "@soar/shared/types"
import { atom } from "jotai"
import { storeItems } from "./localStorage"
import { currentUserAtom } from "./userAtom"

export const locationsForOrganizationAtom = atom<Location[] | null>(null)
export const selectedLocationAtom = atom<string | null>(null)
export const selectedLocationAtomWithStorage = atom(
  (get) => get(selectedLocationAtom),
  (get, set, payload: string | null) => {
    set(selectedLocationAtom, payload)
    const user = get(currentUserAtom)

    // store in local storage
    const storeValue = payload == null ? undefined : payload

    if (user != null) {
      const stored = storeItems(user.id, { locationId: storeValue })
    }
  },
)
export const selectedLocationsAtom = atom<string[] | null>(null)
export const selectedLocationsAtomWithStorage = atom(
  (get) => get(selectedLocationsAtom),
  (get, set, payload: string[] | null) => {
    set(selectedLocationsAtom, payload)
    const user = get(currentUserAtom)

    // store in local storage
    const storeValue = payload == null ? undefined : payload
    if (user != null) {
      const stored = storeItems(user.id, { locationIds: storeValue })
    }
  },
)
