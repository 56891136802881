import { Badge, Box, Button, Center, Flex, Loader, Stack, Text, UnstyledButton } from "@mantine/core"
import { useElementSize } from "@mantine/hooks"
import { SafetyReport, SafetyReportSchema } from "@soar/shared/types"
import { SafetyReportFactorConfig, formatDate } from "@soar/shared/utils"
import { useMemo } from "react"
import { match } from "ts-pattern"
import { CustomLink } from "../customLink"
import { SafetyReportTextStyles } from "../safetyReport"
import { StatusDisplay } from "../statusDisplay"
import { SectionHeader } from "./SectionHeader"

function PendingSafetyReportCard({
  safetyReport,
  url,
}: {
  safetyReport: SafetyReport
  url: string
}) {
  const { ref, width } = useElementSize()
  const numSafetyFactors = 1 // width < 325 ? 1 : 2
  const extraSafetyFactors = safetyReport.factors.length - numSafetyFactors
  return (
    <CustomLink href={url} passHref legacyBehavior>
      <UnstyledButton component="a" ref={ref}>
        <Flex justify="space-between" w="100%">
          <Stack spacing={4} style={{ flexShrink: 1 }}>
            <Flex>
              <Box c="gray.7" fw={700} fz="sm">
                {safetyReport.createdBy != null ? `${safetyReport.createdBy.firstName} ${safetyReport.createdBy.lastName[0]}` : "Anonymous"}
              </Box>
            </Flex>
            {safetyReport.factors.length > 0 && (
              <Flex gap={8} align="center">
                {safetyReport.factors.slice(0, numSafetyFactors).map((factor) => {
                  return (
                    <Badge key={factor} size="xs" variant="filled" color="grape">
                      {SafetyReportFactorConfig[factor].label}
                    </Badge>
                  )
                })}
                {extraSafetyFactors > 0 && (
                  <Text fw={600} fz="xs">
                    +{extraSafetyFactors}
                  </Text>
                )}
              </Flex>
            )}
            <Text
              c="dimmed"
              fz="sm"
              sx={(theme) => ({
                ...SafetyReportTextStyles,
                WebkitLineClamp: 1,
              })}
            >
              {safetyReport.details}
            </Text>
          </Stack>
          <Box fz="xs" fw={600} c="gray.6" ta="right" miw={90}>
            <Text ta="right">{formatDate(safetyReport.createdAt, { date: { format: "long" } })}</Text>
            <Text>{formatDate(safetyReport.createdAt, { time: true })}</Text>
          </Box>
        </Flex>
      </UnstyledButton>
    </CustomLink>
  )
}

export function PendingSafetyReportSection({
  safetyReports,
  viewMoreUrl,
  makeSafetyReportUrl,
  isLoading,
}: {
  safetyReports: SafetyReport[]
  viewMoreUrl: string
  makeSafetyReportUrl: (safetyReportId: string) => string
  isLoading: boolean
}) {
  return (
    <Flex direction="column" justify="space-between" h="100%">
      <Box>
        <SectionHeader title="Open safety reports" timePeriod="All time" />

        {match({ isLoading, safetyReports })
          .with({ isLoading: true }, () => (
            <Center py="xl" mt="xl">
              <Loader variant="bars" mt="xl" />
            </Center>
          ))
          .when(
            ({ safetyReports }) => safetyReports.length > 0,
            ({ safetyReports }) => (
              <Stack>
                {safetyReports.map((safetyReport) => {
                  return (
                    <PendingSafetyReportCard key={safetyReport.id} url={makeSafetyReportUrl(safetyReport.id)} safetyReport={safetyReport} />
                  )
                })}
              </Stack>
            ),
          )
          .otherwise(() => (
            <Box py={36}>
              <StatusDisplay align="flex-start" label="No data for this time period" />
            </Box>
          ))}
      </Box>
      <Flex justify="flex-end" pt="xs">
        <CustomLink href={viewMoreUrl} passHref legacyBehavior>
          <Button component="a" variant="subtle" px={0}>
            View more
          </Button>
        </CustomLink>
      </Flex>
    </Flex>
  )
}
