import { ActionIcon, Text } from "@mantine/core"

export function AirportInputClearButton({
  onClick = () => {},
}: {
  onClick?: () => void
}) {
  return (
    <ActionIcon radius="xl">
      <Text
        span
        color="dark.0"
        className="material-symbols-outlined"
        fz="xs"
        style={{ fontSize: "20px", fontVariationSettings: '"FILL" 1' }}
        onClick={onClick}
      >
        cancel
      </Text>
    </ActionIcon>
  )
}
