import { createStyles } from "@mantine/core"

export const useAirportInputStyles = createStyles((theme) => ({
  airport: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "transparent",
    borderBottomWidth: "1px",
    borderBottomColor: theme.colors.gray[1],
    "&:hover": {
      backgroundColor:
        theme.colors[theme.primaryColor][typeof theme.primaryShade === "number" ? theme.primaryShade : theme.primaryShade.light],
      color: theme.white,
      ".dimmed": {
        color: theme.white,
      },
    },
  },
  selectedAirport: {
    backgroundColor: theme.colors.blue[0],
  },
  wrapperRadius: {
    borderRadius: theme.radius.md,
  },
}))
