import { Altitude } from "@soar/shared/types"
import { P, match } from "ts-pattern"

const feetRegex = "\\d{1,6} ?FT"
const groundRelationQuantifier = "( {0,1}((MSL)|(AGL)))"
const feetWithGroundRelation = `${feetRegex}${groundRelationQuantifier}{0,1}`

const flightLevelRegexBase = (num = 1) => `FL(?<flightLevel${num}>\\\d{2,4})`
const surfaceRegexBase = "(SFC)"

const altitudeRegexBase = `(?<start>(${feetWithGroundRelation})|(${flightLevelRegexBase(
  1,
)})|${surfaceRegexBase}) ?- ?(?<end>(${feetWithGroundRelation})|(${flightLevelRegexBase(2)}))`

export function getNotamAltitudeDimensions(rawText: string) {
  const regex = new RegExp(altitudeRegexBase)
  const matchResults = regex.exec(rawText)

  const beginMatch = matchResults?.groups?.["start"]
  const endMatch = matchResults?.groups?.["end"]

  if (matchResults == null || beginMatch == null || endMatch == null) {
    return undefined
  }
  const beginFlightLevel = matchResults.groups?.["flightLevel1"]
  const endFlightLevel = matchResults.groups?.["flightLevel2"]

  const low = match({ match: beginMatch, flightLevel: beginFlightLevel })
    .with({ match: "SFC" }, () => 0)
    .with({ flightLevel: P.nonNullable }, ({ flightLevel }) => {
      const flightLevelBase = parseInt(flightLevel)
      return isNaN(flightLevelBase) ? undefined : flightLevelBase * 100
    })
    .otherwise(({ match }) => {
      const numbersExec = /\d{1,6}/.exec(match)
      if (numbersExec == null) {
        return undefined
      }
      const flightLevelBase = parseInt(numbersExec[0])
      return isNaN(flightLevelBase) ? undefined : flightLevelBase
    })
  const high = match({ match: endMatch, flightLevel: endFlightLevel })
    .with({ flightLevel: P.nonNullable }, ({ flightLevel }) => {
      const flightLevelBase = parseInt(flightLevel)
      return isNaN(flightLevelBase) ? undefined : flightLevelBase * 100
    })
    .otherwise(({ match }) => {
      const numbersExec = /\d{1,6}/.exec(match)
      if (numbersExec == null) {
        return undefined
      }
      const flightLevelBase = parseInt(numbersExec[0])
      return isNaN(flightLevelBase) ? undefined : flightLevelBase
    })

  if (low == null || high == null || low >= high) {
    return undefined
  }

  return { low, high } satisfies Altitude
}
