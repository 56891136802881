import { ActionIcon, Box, Button, ButtonProps, Checkbox, Select, Stack, Text, Textarea, Title } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { BUTTON_GRADIENT_COLORS, FlightLegResultCard, FlightLegSelect, ModalOrDrawer } from "@soar/frontend/components"
import { organizationsForUserAtom, useAircraft } from "@soar/frontend/contexts"
import { trpc } from "@soar/frontend/trpc-client"
import {
  Aircraft,
  FlightStage,
  FlightStageEnum,
  Organization,
  SafetyReport,
  SafetyReportInput,
  SafetyReportInputSchema,
  SafetyReportSchema,
  SchedulingFlightLegSearchDocument,
} from "@soar/shared/types"
import { FlightStageConfig } from "@soar/shared/utils"
import { IconX } from "@tabler/icons-react"
import { useAtomValue } from "jotai"
import { ReactNode, useEffect, useState } from "react"
import { match } from "ts-pattern"
import { AirportInput } from "../airports/airportInput"

export function SafetyReportingButton({
  children = "Open",
  buttonProps = {},
}: {
  children?: ReactNode
  buttonProps?: ButtonProps
}) {
  const organizations = useAtomValue(organizationsForUserAtom)
  const [modalState, modalHandlers] = useDisclosure(false)
  if ((organizations ?? []).length === 0) {
    return <Box />
  }
  return (
    <>
      <Button {...buttonProps} onClick={modalHandlers.open}>
        {children}
      </Button>
      <SafetyReportModal opened={modalState} onClose={modalHandlers.close} />
    </>
  )
}

export function SafetyReportModal({
  opened,
  onClose = () => {},
  onSuccess = () => {},
}: {
  opened: boolean
  onClose?: () => void
  onSuccess?: (post: SafetyReport) => void
}) {
  const { mutateAsync } = trpc.safetyReport.createSafetyReport.useMutation()
  const organizations = useAtomValue(organizationsForUserAtom)
  const { aircraftForUser, aircraftOptions } = useAircraft()
  const { data } = trpc.scheduling.getSchedulingFlightLegs.useQuery(
    {
      organizationId: "",
    },
    {},
  )

  const handleSubmit = async (safetyReport: SafetyReportInput) => {
    const safetyReportResponse = await mutateAsync({
      ...safetyReport,
    })
    const safetyReportParsed = SafetyReportSchema.parse(safetyReportResponse)
    onClose()
    showNotification({
      color: "green",
      message: "Safety report has been submitted!",
    })
    onSuccess(safetyReportParsed)
  }

  return (
    <ModalOrDrawer
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Submit safety report</Title>}
      modalProps={{
        size: "xl",
      }}
    >
      <Box pb="lg">
        <SafetyReportForm organizations={organizations ?? []} onSubmit={handleSubmit} aircraftOptions={aircraftOptions} />
      </Box>
    </ModalOrDrawer>
  )
}

const stageOfFlightOptions = Object.keys(FlightStageConfig).map((key) => {
  const config = FlightStageConfig[key]
  return {
    label: config.label,
    value: key,
  }
})

function getRelevantStageOfFlightAirports(flightStage: FlightStage | null | undefined) {
  if (flightStage == null) {
    return {
      departure: true,
      destination: false,
    }
  }
  return FlightStageConfig[flightStage].appliesTo
}

function SafetyReportForm({
  onSubmit = () => {},
  organizations,
  aircraftOptions,
}: {
  onSubmit?: (safetyReport: SafetyReportInput) => void
  organizations: Organization[]
  aircraftOptions: Array<{ label: string; value: string; tailNumber: string; organizationId: string | undefined | null }>
}) {
  const form = useForm<SafetyReportInput>({
    initialValues: {
      details: "",
      organizationId: "",
      isAnonymous: false,
    },
    validate: zodResolver(SafetyReportInputSchema),
  })
  const [flightLeg, setFlightLeg] = useState<SchedulingFlightLegSearchDocument>()
  const [departureAirport, setDepartureAirport] = useState("")
  const [destinationAirport, setDestinationAirport] = useState("")

  useEffect(() => {
    if (organizations != null) {
      form.setValues({
        organizationId: organizations[0].id,
      })
    }
  }, [organizations])

  const stageOfFlightAirportRelevance = getRelevantStageOfFlightAirports(form.values.stageOfFlight)

  useEffect(() => {
    const airports: string[] = []
    const stageOfFlightAirportRelevance = getRelevantStageOfFlightAirports(form.values.stageOfFlight)

    if (stageOfFlightAirportRelevance.departure) {
      airports.push(departureAirport)
    }
    if (stageOfFlightAirportRelevance.destination) {
      airports.push(destinationAirport)
    }
    form.setFieldValue("airport", airports)
  }, [departureAirport, destinationAirport, form.values.stageOfFlight])

  const onFlightLegSelect = (flightLeg: SchedulingFlightLegSearchDocument) => {
    form.setFieldValue("organizationId", flightLeg.organizationId ?? "")
    form.setFieldValue("flightId", flightLeg.id)
    form.setFieldValue("dateOfIncidence", new Date(flightLeg.departAt))

    setDepartureAirport(flightLeg.departureAirport)
    setDestinationAirport(flightLeg.destinationAirport)

    const matchedAircraft = aircraftOptions.find(
      (option) => option.tailNumber === flightLeg.aircraftTailNumber && option.organizationId === flightLeg.organizationId,
    )
    form.setFieldValue("aircraftId", matchedAircraft?.value)
    setFlightLeg(flightLeg)
  }
  const onRemoveFlightLegClick = () => {
    form.setFieldValue("organizationId", "")
    form.setFieldValue("flightId", undefined)
    setFlightLeg(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(async (values) => {
        onSubmit(values)
      })}
    >
      <Stack>
        {flightLeg == null ? (
          <FlightLegSelect
            onSelect={onFlightLegSelect}
            textInputProps={{
              label: "Flight leg (optional)",
            }}
          />
        ) : (
          <FlightLegResultCard flightLeg={flightLeg}>
            <Box>
              <Button variant="subtle" onClick={onRemoveFlightLegClick} px={0}>
                Change flight
              </Button>
            </Box>
          </FlightLegResultCard>
        )}
        <Select data={stageOfFlightOptions} label="Stage of flight (optional)" {...form.getInputProps("stageOfFlight")} />
        <Select
          label="Organization"
          data={organizations?.map((org) => ({ value: org.id, label: org.name })) ?? []}
          {...form.getInputProps("organizationId")}
        />

        <DatePickerInput label="Date" {...form.getInputProps("dateOfIncidence")} />
        {stageOfFlightAirportRelevance.departure && (
          <Box>
            <Text fz="sm" fw={500}>
              Departure Airport
            </Text>
            <AirportInput
              onSelect={(airport) => {
                setDepartureAirport(airport?.icao_id ?? airport?.faa_airport_id ?? "")
              }}
              value={departureAirport}
            />
          </Box>
        )}
        {stageOfFlightAirportRelevance.destination && (
          <Box>
            <Text fz="sm" fw={500}>
              Destination Airport
            </Text>
            <AirportInput
              onSelect={(airport) => {
                setDestinationAirport(airport?.icao_id ?? airport?.faa_airport_id ?? "")
              }}
              value={destinationAirport}
            />
          </Box>
        )}

        <Select data={aircraftOptions} label="Equipment" {...form.getInputProps("aircraftId")} />

        <Textarea label="Report details" {...form.getInputProps("details")} autosize minRows={5} />

        <Checkbox label="Anonymous report?" {...form.getInputProps("isAnonymous")} />

        <Button variant="gradient" gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }} type="submit">
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
